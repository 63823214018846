<div class="login">
  <div class="login__banner-container">
    <img class="login__banner-container__company-logo" src="assets/vgt_left_side_logo.png" alt={{companyName}}>
  </div>

  <div class="login__main-container">
    <div class="login__main-container__background-animation-area">
      <ul class="login__main-container__background-animation-area__animation-objects">
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
      </ul>
    </div>

    <img
      class="login__main-container__app-name"
      src="assets/logo_top_white.png"
      alt={{appName}}
      [ngClass]="isTerminalDevice ? 'login__main-container__app-name--terminal':'login__main-container__app-name--desktop'">


      <div class="login__main-container__login-form-container">
        <ng-template [ngIf]="!showQrCodeLoginForm">
        <form
          [formGroup]="formGroup"
          (ngSubmit)="onLogin()"
          #loginFormTemplate="ngForm">
          <label for="email">{{ 'common.email' | translate }}</label>
          <input
            #emailInput
            autofocus
            id="email"
            type="text"
            class="global-input login__main-container__login-form-container__input global-margin--bottom-8-percent"
            formControlName="username"
            appVirtualKeyboard
            [formGroupDirective]="loginFormTemplate"
            [control]="usernameControl"
            [ngClass]="usernameInputClass"
            [placeholder]="'common.email' | translate"
            (focusin)="scrollToEmailInput()"
            (focus)="isTerminalDevice ? emailPlaceholder.className = 'display--true login__main-container__login-form-container--email-input-keyboard-placeholder'
             :'global-height--0' "
            (blur)="emailPlaceholder.className = 'display--false'">
          <div
            #emailPlaceholder
            class="display--false "></div>
          <label for="password">{{ 'common.password' | translate }}</label>
          <input
            #passwordInput
            id="password"
            type="password"
            class="global-input login__main-container__login-form-container__input"
            formControlName="password"
            appVirtualKeyboard
            [formGroupDirective]="loginFormTemplate"
            [control]="passwordControl"
            [ngClass]="passwordInputClass"
            [placeholder]="'common.password' | translate"
            (focusin)="scrollToPasswordInput()"
            (focus)=" isTerminalDevice ? passwordPlaceholder.className = 'display--true login__main-container__login-form-container--password-input-keyboard-placeholder'
             : 'global-height--0'"
            (blur)="passwordPlaceholder.className = 'display--false'">
          <div
            #passwordPlaceholder
            class="display--false ">
          </div>

          <div class="global-flex global-justify__center">
            <button
              type="submit"
              class="global-button"
              data-cy="submit-button"
              [ngClass]="isTerminalDevice ? 'login__main-container__login-form-container__submit-button--terminal': 'login__main-container__login-form-container__submit-button--desktop'"
              [disabled]="formGroup.invalid"
            >{{ 'common.login' | translate }}
            </button>
          </div>
        </form>

        <button
          type="button"
          class="global-button"
          (click)="toggleShowQrCodeLoginFormFlag()"
        >{{ 'common.log-in-fast-with-qr-code' | translate }}
        </button>

        <button
          type="button"
          class="button login__main-container__login-form-container__forgot-password-button global-margin--none"
          data-cy="forgot-password-button"
          (click)="onPasswordReset()"
        >{{ 'common.forgot-password' | translate }}
        </button>
        </ng-template>

        <ng-template [ngIf]="showQrCodeLoginForm">
          <div class="global-flex global-justify__center">
            <app-login-with-qr-code
              (closeViewEmitter)="toggleShowQrCodeLoginFormFlag()"
            ></app-login-with-qr-code>
          </div>
        </ng-template>
      </div>

    <div class="login__main-container__development-year">
      <p>{{ developmentYear }}</p>
    </div>
  </div>

</div>
