import {AfterViewInit, Component, ElementRef, EventEmitter, OnInit, Output, ViewChild} from '@angular/core';
import {AuthService} from '../../auth/auth.service';
import {ActivatedRoute} from '@angular/router';
import {TranslateModule} from "../../translations/translate.module";
import {FormsModule} from "@angular/forms";

@Component({
  selector: 'app-login-with-qr-code',
  templateUrl: './login-with-qr-code.component.html',
  imports: [
    TranslateModule,
    FormsModule
  ]
})
export class LoginWithQrCodeComponent implements OnInit, AfterViewInit {

  public loginInputValue: string;
  private returnUrl: string;

  @ViewChild('loginInput') loginInput: ElementRef;
  @Output() public closeViewEmitter: EventEmitter<void> = new EventEmitter<void>();

  constructor(private authService: AuthService,
              private route: ActivatedRoute,) {
  }

  public ngOnInit(): void {
    this.returnUrl = this.route.snapshot.queryParams[`returnUrl`] || '/';
  }

  public ngAfterViewInit(): void {
    this.loginInput.nativeElement.focus();
  }

  public loginWithQrCode(qrCode: string): void {
    this.authService.fastLoginWithQrCode(qrCode, this.returnUrl);
  }


}
