import {Component, OnInit} from '@angular/core';
import {AbstractControl, FormBuilder, FormGroup, Validators} from '@angular/forms';
import {AuthService} from '../auth/auth.service';
import {NewPasswordService} from '../new-password/new-password.service';
import {Router} from '@angular/router';
import {NotificationService} from '../../shared/services/notification.service';
import {NotificationMessageType} from '../../shared/enums/NotificationMessageType';
import {ConstanceValues} from '../../shared/constance-values/constance-values';
import {SpinnerService} from '../../shared/services/spinner.service';
import {validateMatchingPasswords} from '../../shared/custom-validators/repeat-password-validator';


@Component({
    selector: 'app-password-change',
    templateUrl: './password-change.component.html',
    standalone: false
})
export class PasswordChangeComponent implements OnInit {

  public formGroup: FormGroup;

  constructor(private authService: AuthService,
              private spinnerService: SpinnerService,
              private formBuilder: FormBuilder,
              private newPasswordService: NewPasswordService,
              private notificationService: NotificationService,
              private router: Router) {
  }

  public ngOnInit(): void {
    this.initFormGroup();
  }

  private initFormGroup(): void {
    const passwordValidators = [Validators.required, Validators.maxLength(ConstanceValues.MAX_STRING_LENGTH)];
    this.formGroup = this.formBuilder.group({
      oldPassword: ['', passwordValidators],
      newPassword: ['', passwordValidators],
      newPasswordRepeat: ['', passwordValidators],
    }, {validators: validateMatchingPasswords});
  }

  public onSubmit(): void {
    if (this.newPasswordControl.value !== this.newPasswordRepeatControl.value) {
      this.notificationService.displayNotificationToast(
        'user-self-management.new-password-and-confirm-password-must-be-the-same',
        NotificationMessageType.WARNING);
    } else {
      this.changeUserCurrentPassword();
    }
  }

  private changeUserCurrentPassword(): void {
    this.spinnerService.activateSpinner();
    this.newPasswordService.changeCurrentPassword(this.oldPasswordControl.value, this.newPasswordControl.value).subscribe(() => {
      this.spinnerService.deactivateSpinner();
      this.notificationService.displayNotificationToast('user-self-management.password-changed-correctly', NotificationMessageType.SUCCESS);
      this.redirectToUserProfile();
    }, (error) => this.notificationService.handleErrorResponseWithMessage(error, this.spinnerService));
  }

  public redirectToUserProfile(): void {
    this.router.navigate(['bh/profile']);
  }

  public get oldPasswordControl(): AbstractControl {
    return this.formGroup.get('oldPassword');
  }

  public get newPasswordControl(): AbstractControl {
    return this.formGroup.get('newPassword');
  }

  public get newPasswordRepeatControl(): AbstractControl {
    return this.formGroup.get('newPasswordRepeat');
  }

}
