import {Injectable} from '@angular/core';
import {SecurityCtrl} from '../sdk/bighero-controllers';
import {Observable} from 'rxjs';

@Injectable()
export class PasswordResetService {

  constructor(private securityCtrl: SecurityCtrl) {
  }

  public passwordReset(email: string): Observable<void> {
    return this.securityCtrl.resetPassword(email);
  }
}
