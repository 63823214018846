/* eslint-disable  max-len,  eol-last,  no-trailing-spaces,  @typescript-eslint/type-annotation-spacing,  @typescript-eslint/no-unused-vars,   */
import {JsonScopedSerializer} from './JsonParser';
import {ErrorHandlerService} from './error-handler.service';
import {JsonScope} from './jsonScope';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import {Injectable} from '@angular/core';
import {DefaultPermission, Permission} from './model-permission';
import {Observable, Subject} from 'rxjs';
import {map} from 'rxjs/operators';

@Injectable()
export class DefaultPermissionCtrl {
  errorHandlerService: ErrorHandlerService;
  httpService: HttpClient;


 public constructor(httpService: HttpClient, errorHandlerService: ErrorHandlerService) {
    this.httpService = httpService;
    this.errorHandlerService = errorHandlerService;
  }

 public countAllLeftPermissionsForRole(personRoleEnum: number): Observable<number>  {
    const queryParamsList: { name: string, value: string }[] = [];
    queryParamsList.push({name: 'personRoleEnum', value: personRoleEnum.toString()});
      let params = new HttpParams();
    for (const queryParam of queryParamsList) {
      params = params.append(queryParam.name, queryParam.value);
    }
   const subject = new Subject<number>();
    this.httpService.get('/api/default-permission/r/count-left-permissions', {params, responseType: 'text'}).pipe(map(res => JsonScopedSerializer.parse(res)))
      .subscribe(res => subject.next(res), error => {
       this.errorHandlerService.handleErrors(error);
       subject.error(error);
    });
    return subject.asObservable();
  }

 public countDefaultPermissionForPersonRoleEnum(personRoleEnum: number): Observable<number>  {
    const queryParamsList: { name: string, value: string }[] = [];
    queryParamsList.push({name: 'personRoleEnum', value: personRoleEnum.toString()});
      let params = new HttpParams();
    for (const queryParam of queryParamsList) {
      params = params.append(queryParam.name, queryParam.value);
    }
   const subject = new Subject<number>();
    this.httpService.get('/api/default-permission/r/count-default-permissions-for-role-enum', {params, responseType: 'text'}).pipe(map(res => JsonScopedSerializer.parse(res)))
      .subscribe(res => subject.next(res), error => {
       this.errorHandlerService.handleErrors(error);
       subject.error(error);
    });
    return subject.asObservable();
  }

 public createDefaultPermissionList(defaultPermissionList: DefaultPermission[], jsonScope: JsonScope = new JsonScope(false, [])): Observable<DefaultPermission[]>  {
    const headers = new HttpHeaders().set('Content-type', 'application/json');
   const subject = new Subject<DefaultPermission[]>();
    this.httpService.post('/api/default-permission/w/create-default-permissions', JsonScopedSerializer.stringify(defaultPermissionList, jsonScope) , {headers, responseType: 'text'}).pipe(map(res => JsonScopedSerializer.parse(res)))
      .subscribe(res => subject.next(res), error => {
       this.errorHandlerService.handleErrors(error);
       subject.error(error);
    });
    return subject.asObservable();
  }

 public createObject(entity: DefaultPermission, jsonScope: JsonScope = new JsonScope(false, [])): Observable<DefaultPermission>  {
    const headers = new HttpHeaders().set('Content-type', 'application/json');
   const subject = new Subject<DefaultPermission>();
    this.httpService.post('/api/default-permission/w/', JsonScopedSerializer.stringify(entity, jsonScope) , {headers, responseType: 'text'}).pipe(map(res => JsonScopedSerializer.parse(res)))
      .subscribe(res => subject.next(res), error => {
       this.errorHandlerService.handleErrors(error);
       subject.error(error);
    });
    return subject.asObservable();
  }

 public deleteDefaultPermissionList(defaultPermissionIDsList: string[], jsonScope: JsonScope = new JsonScope(false, [])): Observable<void>  {
    const headers = new HttpHeaders().set('Content-type', 'application/json');
   const subject = new Subject<void>();
    this.httpService.post<void>('/api/default-permission/w/delete-default-permissions', defaultPermissionIDsList , {headers})
      .subscribe(res => subject.next(res), error => {
       this.errorHandlerService.handleErrors(error);
       subject.error(error);
    });
    return subject.asObservable();
  }

 public deleteObject(id: string): Observable<void>  {
   const subject = new Subject<void>();
    this.httpService.delete<void>('/api/default-permission/w/' + id + '')
      .subscribe(res => subject.next(res), error => {
       this.errorHandlerService.handleErrors(error);
       subject.error(error);
    });
    return subject.asObservable();
  }

 public getAllLeftPermissionsForRole(personRoleEnum: number, pageNumber: number, pageSize: number): Observable<Permission[]>  {
    const queryParamsList: { name: string, value: string }[] = [];
    queryParamsList.push({name: 'personRoleEnum', value: personRoleEnum.toString()});
  
    queryParamsList.push({name: 'pageNumber', value: pageNumber.toString()});
  
    queryParamsList.push({name: 'pageSize', value: pageSize.toString()});
      let params = new HttpParams();
    for (const queryParam of queryParamsList) {
      params = params.append(queryParam.name, queryParam.value);
    }
   const subject = new Subject<Permission[]>();
    this.httpService.get('/api/default-permission/r/get-left-permissions', {params, responseType: 'text'}).pipe(map(res => JsonScopedSerializer.parse(res)))
      .subscribe(res => subject.next(res), error => {
       this.errorHandlerService.handleErrors(error);
       subject.error(error);
    });
    return subject.asObservable();
  }

 public getDefaultPermissionForPersonRoleEnum(personRoleEnum: number, pageNumber: number, pageSize: number): Observable<DefaultPermission[]>  {
    const queryParamsList: { name: string, value: string }[] = [];
    queryParamsList.push({name: 'personRoleEnum', value: personRoleEnum.toString()});
  
    queryParamsList.push({name: 'pageNumber', value: pageNumber.toString()});
  
    queryParamsList.push({name: 'pageSize', value: pageSize.toString()});
      let params = new HttpParams();
    for (const queryParam of queryParamsList) {
      params = params.append(queryParam.name, queryParam.value);
    }
   const subject = new Subject<DefaultPermission[]>();
    this.httpService.get('/api/default-permission/r/get-default-permissions-for-role-enum', {params, responseType: 'text'}).pipe(map(res => JsonScopedSerializer.parse(res)))
      .subscribe(res => subject.next(res), error => {
       this.errorHandlerService.handleErrors(error);
       subject.error(error);
    });
    return subject.asObservable();
  }

}

