<div class="password-change">
  <h1>{{'common.set-new-password' | translate}}</h1>
  <form
    *ngIf="formGroup"
    class="password-change__form"
    [formGroup]="formGroup"
    (ngSubmit)="onSubmit()">
    <div class="password-change__form__fields">
      <div class="password-change__form__fields__record">
        <label for="newPassword">{{'common.new-password' | translate}}:</label>
        <input
          id="newPassword"
          type="password"
          placeholder="{{'common.new-password' | translate}}"
          formControlName="newPassword">
        <span
          *ngIf="newPasswordControl.invalid && (newPasswordControl.dirty || newPasswordControl.touched)"
          class="global-error-message"
        >{{'common.valid-value-required' | translate}}</span>
      </div>
      <div class="password-change__form__fields__record">
        <label for="newPasswordRepeat">{{'common.confirm-password' | translate}}:</label>
        <input
          id="newPasswordRepeat"
          type="password"
          placeholder="{{'common.confirm-password' | translate}}"
          formControlName="newPasswordRepeat">
        <span
          *ngIf="newPasswordRepeatControl.invalid && (newPasswordRepeatControl.dirty || newPasswordRepeatControl.touched)"
          class="global-error-message"
        >{{'common.valid-value-required' | translate}}</span>
        <span
          *ngIf="formGroup.hasError('passwordsDoNotMatch') && (newPasswordRepeatControl.dirty || newPasswordRepeatControl.touched)"
          class="global-error-message"
        >{{'common.passwords-dont-match' | translate}}</span>

      </div>
    </div>
    <div class="password-change__form__buttons">
      <button
        type="submit"
        [disabled]="formGroup.invalid"
      >{{'common.submit' | translate}}</button>
      <button
        type="button"
        (click)="returnToLoginPage()"
      >{{'common.return' | translate}}</button>
    </div>
  </form>
</div>
