<div class="global-flex global-flex-direction__column global-align__items-center">
    <div class="form-details__field">
        <label for="login">{{ 'common.fast-login-with-qr-code' | translate }}</label>
        <input
                class="global-width--important-20vw global-height--5vh"
                autofocus
                id="login"
                #loginInput
                [(ngModel)]="loginInputValue"
                placeholder="{{'common.please-scan-your-qr-code-fast-pass' | translate}}"
                (keyup.enter)="loginWithQrCode(loginInput.value)">
    </div>
    <div class="global-flex">
        <button
                type="button"
                class="global-button"
                [disabled]="loginInputValue == null || loginInputValue === ''"
                (click)="loginWithQrCode(loginInput.value)"
        >{{ 'common.login' | translate }}
        </button>
        <button
                type="button"
                class="global-button"
                (click)="closeViewEmitter.emit()"
        >{{ 'common.return' | translate }}
        </button>
    </div>
</div>
