import {Component, ViewContainerRef} from '@angular/core';
import {BaseSubNavbarComponentFactoryService} from '../../../shared/services/base-sub-navbar-component-factory.service';

@Component({
    selector: 'app-base-sub-navbar-container',
    templateUrl: './base-sub-navbar-container.component.html',
    styles: [],
    standalone: false
})
export class BaseSubNavbarContainerComponent {

  constructor(private vc: ViewContainerRef,
              private baseSubNavbarComponentFactoryService: BaseSubNavbarComponentFactoryService) {
    this.baseSubNavbarComponentFactoryService.container = vc;
  }

}
