<div class="form-container">

  <div class="form-center form-container__card">
         <span class="form-container__title form-container__title--color">{{'common.password-reset' | translate}}</span>
    <app-base-form
      [formFields]="formFields"
      [showCancelButton]="true"
      [customConfirmButtonStyle]="'button button__submit-button button__submit-button--background-color button__submit-button--color'"
      [customDeclineButtonStyle]="'button button__submit-button button__submit-button--background-color button__submit-button--color'"
      (formSubmitted)="onReset($event)"
      (formCancelled)="goBack()">
    </app-base-form>
  </div>
</div>



